import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { _getTokenFromStorage, convertToMoney, removeTime } from '../utils';
import StorageService from '../utils/storageService';
import { logoutAsync, refreshTokenAsync, userDetailsAsync, getUserWalletAsync, createDecisionAsync, 
    getPendingDecisionsAsync, setCurrentDecisionAsync, getAvailableBanks,
} from '../redux/actions';
import { useNavigate } from 'react-router-dom';
import { clearLoanRequestAsync } from '../redux/actions';
import Logo3 from './Logo3';
import { toast } from 'react-toastify';
import pendingIcon from '../assets/cancel_small.svg'
import successIcon from '../assets/check.svg'
import loadingIcon from '../assets/loading.gif'
import Footnotes from './Footnotes';

export default function LoanHistory() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [creatingDecisioning, setCreatingDecisioning] = useState(false)
    const [startedCreatingDecisioning, setStartedCreatingDecisioning] = useState(false)
    const [loans, setLoans] = useState([])
    const [buttonText, setButtonText] = useState('Apply for BNPL')
    const [loadingText, setLoadingText] = useState('')
    const [partialRepaymentId, setPartialRepaymentId] = useState(0)
    const [expandItem, setExpandItem] = useState(-1)
    const [currentRepayments, setCurrentRepayment] = useState([])
    const [sortedLoans, setSortedLoans] = useState([])
    const storageService = new StorageService()
    const {accessToken, companyDetails, 
        userDetails, pending_decision,
        refreshToken
    } = useSelector(
        (state) => state.auth
    )

    const toggleExpand = (itemId) =>{
        if(expandItem === -1 || itemId !== expandItem){
            setExpandItem(itemId)
        }else{
            setExpandItem(-1)
            setCurrentRepayment([])
        }
    }

    const getLoans = () =>{
        
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
              },
        };
          setLoading(true)
          setLoadingText('...Getting BNPL History')

          fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/loans`, requestOptions)
            .then(response => {
                if(response.status === 401){
                    dispatch(refreshTokenAsync())
                }   
                return response.json()
            })
            .then(result => {

                let filteredLoans = [] 
                if(result.succeded){
                    filteredLoans = result.data.filter((thisLoan, index)=>{
                        if(companyDetails.id === thisLoan.companyId){
                            return true
                        }else{
                            return false
                        }
                    })

                    setLoans(filteredLoans)
                    setLoading(false)
                    setLoadingText('')
                }
            })
            .catch(error => 
                {
                    setLoading(false)
                    setLoadingText('')
                });
    }

    const makePayment = (loanId, fullPayment) =>{
        if(!loading){
            var requestOptions = {
            method: 'PUT',
            redirect: 'follow',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                },
            };
            setLoading(true)
            setLoadingText('...Making Payment')

            fetch(`${process.env.REACT_APP_BACKEND_BASEURL}customer/loan/pay?loanId=${loanId}&fullPayment=${fullPayment}`, requestOptions)
            .then(response => {
                if(response.status === 401){
                    dispatch(refreshTokenAsync())
                }else{

                }   
                return response.json()
            })
            .then(result => {
            setLoading(false)
            setLoadingText('')

            if(result.succeded){
                //add action to session
                var sessionId = _getTokenFromStorage('sessionId')
                let auth_data = storageService.getAuthData()
                let session_data_updated = storageService.getSessionData()
                // if(fullPayment){
                //     storageService.createEvent(session_data_updated.sessionId, "MakeFullRepayment",auth_data.accessToken)
                // }else{
                //     storageService.createEvent(session_data_updated.sessionId, "MakeRepayment",auth_data.accessToken)
                // }

                getLoans()
            }else{
                toast.error(result.message, {
                    position: "top-right",
                    autoClose:false,
                })
            }

            //to find alternative to reload later
            // window.location.reload();

            })
            .catch(error => 
            {
                setLoading(false)
                setLoadingText('')
            });
        }
    }
    useEffect(()=>{
        if(accessToken !== ''){
            getLoans()
        }
    },[accessToken])

    useEffect(()=>{
        setSortedLoans([...loans].reverse())
    },[loans])

    useEffect(()=>{
       dispatch(clearLoanRequestAsync()) 
    },[])    

    useEffect(()=>{
        dispatch(getAvailableBanks({
            accessToken : accessToken
        },
            ()=>{}
        ))
    },[])

   

    useEffect(()=>{
        // this_loan.repayments.reverse()
        if(typeof sortedLoans[expandItem] !== 'undefined'){
            let m = sortedLoans[expandItem].repayments
            let x = []
            let z = {}
            let y = []
            let l = {}
            let j = []
            if(typeof sortedLoans[expandItem].repayments[0] !== 'undefined'){
                for(let i = 0; i < m.length ; i++){
                    let timestamp = new Date(m[i].dueDate);
                    let unixTimestamp = Math.floor(timestamp.getTime() / 1000)
                    y.push(unixTimestamp)
                    l[unixTimestamp] = sortedLoans[expandItem].repayments[i]
                    if(!(m[i].paymentCompleted)){
                        x.push(unixTimestamp)
                        z[unixTimestamp] = m[i].id
                    }
                }
            }

            let r = x.sort(function(a, b){return a - b})
            let s = y.sort(function(a, b){return a - b})

            if(typeof r[0] !== 'undefined'){
                if(r.length > 1){
                    setPartialRepaymentId(z[r[0]])
                }else{
                    setPartialRepaymentId(0) 
                }
            }else{
                setPartialRepaymentId(0) 
            }
            if(typeof s[0] !== 'undefined'){
                for(let i = 0 ; i < s.length; i++){
                    j.push(l[s[i]])        
                }
            }

            setCurrentRepayment(j)
        }

    },[expandItem, sortedLoans])

    useEffect(()=>{

        if(typeof accessToken !== "undefined"){
            dispatch(getPendingDecisionsAsync({accessToken : accessToken, companyShortCode: companyDetails.shortCode}))
            dispatch(userDetailsAsync({accessToken: accessToken}))
            dispatch(getUserWalletAsync({accessToken: accessToken}))
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[accessToken])

    useEffect(()=>{
        // console.log(companyDetails)
        if(typeof companyDetails.loanApplicationText !== "undefined"){
            if(companyDetails.loanApplicationText !== ""){
                setButtonText(companyDetails.loanApplicationText)
            }

        }
    },[companyDetails])

    useEffect(()=>{
        if(creatingDecisioning && !startedCreatingDecisioning){
            setStartedCreatingDecisioning(true)
            dispatch(createDecisionAsync(
                {companyShortCode: companyDetails.shortCode},
                (thisDecision)=>{
                    // console.log("this decision", thisDecision)
                    
                    dispatch(getPendingDecisionsAsync({accessToken : accessToken, companyShortCode: companyDetails.shortCode},()=>{
                        let index = 0
                        // console.log(pending_decision)
                        if(pending_decision !== null || JSON.stringify(pending_decision) !== "[]"){
                            index = pending_decision.length
                        }
                        dispatch(setCurrentDecisionAsync({
                            current_decision: thisDecision,
                            pendingIndex: index,
                        },()=>{
                            navigate('/request')
                            setCreatingDecisioning(false)
                            setStartedCreatingDecisioning(false)
                        },()=>{
                            setCreatingDecisioning(false)
                            setStartedCreatingDecisioning(false)
                        },
                    ))
                    }))
                })
            )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[creatingDecisioning])



    return (
        <div className='izi_box bnpl_modal'>
            <Logo3 
                title={typeof companyDetails !== "undefined" ? companyDetails.name : ""}
            />
            {accessToken !== '' && typeof userDetails !== 'undefined' &&
                <div className='logout bg-gray-800'
                    onClick={()=>{
                    dispatch(logoutAsync())
                    }}
                >Logout</div>
            }
            {typeof userDetails.firstName !== 'undefined' &&
                <div className='flex flex-row mt-[40px] justify-between'>
                    <div className='text-xs py-[5px]'>{`Welcome, ${userDetails.firstName.charAt(0).toUpperCase() + userDetails.firstName.slice(1)}`}</div>
                    <div className='bg-white hover:bg-[#2c3e50]
                    text-[#2c3e50] hover:text-white
                    h-[25px] text-xs 
                    px-[20px] py-[5px] 
                    rounded-[5px] cursor-pointer'
                    style={{
                        color:'#fff',
                        background:'var(--secondaryColourHexCode)'
                    }}
                    onClick={()=>{
                        navigate('/payment-options')
                    }}
                    >Payment Method</div>
                </div>
            }
            
            {(pending_decision !== null 
            && JSON.stringify(pending_decision) === "[]" 
            && typeof pending_decision !== "undefined" 
            && !loading) &&
            

            <div className='dashboard_top mt-[20px]'>
                <div className={`button h-[45px] py-[10px] px-[20px] flex gap-[10px]
                ${creatingDecisioning ? 'button-loading':''}
                `}
                onClick={async ()=>{
                    if(!creatingDecisioning){
                        setCreatingDecisioning(true)
                    }
                }}
                >
                    {creatingDecisioning && <img className="w-[20px] h-[20px] rounded-[30px]" src={loadingIcon} alt='loadingIcon' /> }
                    {buttonText}</div>
            </div>

            }
            {pending_decision !== null && typeof pending_decision !== 'undefined' &&
            <>
                {pending_decision.length > 0 &&
                    <div className='mt-[30px]'>
                        <div className='font-bold text-[13px]'
                        style={{color:'var(--primaryColourHexCode)'}}
                        >{`Pending Request${pending_decision.length > 1 ? 's' : '' }`}</div>
                        {pending_decision.map((pd,index)=>{
                            return(
                                <div key={index}>
                                {/* {pd.isMonoSetupCompleted === true && */}
                                <div className='shadow-sm bg-white p-[10px] rounded-[10px] my-[10px]'>
                                    <div className='top flex justify-between pb-[10px]'>
                                        <div className='text-[10px] font-bold text-gray-600 pt-[4px]'>{removeTime(pd.updatedAt)}</div>
                                        <div className='font-medium text-[10px] '
                                        style={{color:'var(--secondaryColourHexCode)'}}
                                        >{pd.paymentMethod}</div>
                                    </div>
                                    {/* <div className='h-[1px] bg-gray-200 my-[10px]'></div> */}
                                    <div className='flex justify-between  between 
                                    ' key={index}
                                    onClick={
                                        ()=>{
                                            let thisDecision = pd

                                            dispatch(setCurrentDecisionAsync({
                                                current_decision: thisDecision,
                                                pendingIndex: index,
                                            },()=>{
                                                // console.log(thisDecision)
                                                navigate('/request')
                                            }))

                                        }
                                    }
                                    >
                                        {/* <div className='p-[10px] text-[12px]'>{pd.id}</div> */}

                                        <div className=''>
                                            <div className='text-[10px] text-gray-800 font-bold'>Requested amount</div>

                                            <div className={`text-[12px] font-bold
                                            ${pd.requestedAmount > 0 ? 'green_text':'text-gray-400'}
                                            `}>{pd.requestedAmount > 0 ? convertToMoney(pd.requestedAmount) : "---"}</div>
                                        </div>
                                        <div className='small-button w-[100px] h-[30px] p-[5px] text-center text-[12px] text-white rounded-[2px]'

                                        >Continue</div>
                                    </div>
                                    <div className='h-[1px] border-b-[1px] border-dotted border-gray-400 mt-[10px] mb-[5px]
                                    '></div>



                                    <div className='flex 
                                        justify-between align-center
                                        min-w-[150px] text-[10px] gap-[1px] px-[5px]'>
                                            {!companyDetails.skipDecisioning &&
                                            <>
                                            <div className={`flex gap-[3px]
                                            min-w-[30px] pt-[4px] rounded-[5px]
                                            
                                            ${pd.isBankLinked ? 'green_text': 'red_text'}
                                            `}>
                                                <img src={pd.isBankLinked ? successIcon : pendingIcon } className='w-[8px] mt-[1px]' alt="successIcon" />
                                                <span className='pt-[1px] font-bold'>Link Bank</span>
                                            </div>
                                            
                                            <div className={`text-[10px] pt-[5px]
                                            ${pd.isBankLinked ? 'green_text' : 'red_text' }
                                            `}> {'>'} </div>
                                            </>}
                                            <div 
                                            className={`flex gap-[3px]
                                            min-w-[30px] pt-[4px] rounded-[5px]
                                            ${pd.isKYCInformationSubmitted ? 'green_text': 'red_text'}
                                            `}
                                            >
                                                <img src={pd.isKYCInformationSubmitted ? successIcon : pendingIcon } className='w-[8px] mt-[1px]' alt="pending-icon" />
                                                <span className='pt-[1px] font-bold'>KYC</span>
                                            </div>

                                            <div className={`text-[10px] pt-[5px]
                                            ${pd.isKYCInformationSubmitted > 0 ? 'green_text' : 'red_text' }
                                            `}> {'>'} </div>

                                            <div
                                            className={`flex gap-[3px]
                                            min-w-[30px] pt-[4px] rounded-[5px]
                                            ${pd.status >= 1 ? 'green_text': 'red_text'} `}
                                            >
                                                <img src={ pd.status >= 1 ? successIcon : pendingIcon } className='w-[8px] mt-[1px]'  alt="success-icon"/>
                                                <span className='pt-[1px] font-bold'>Request</span>
                                            </div>

                                            <div className={`text-[10px] pt-[5px]
                                            ${pd.status >= 1 ? 'green_text' : 'red_text' }
                                            `}> {'>'} </div>

                                            <div
                                            className={`flex gap-[3px]
                                            min-w-[30px] pt-[4px] rounded-[5px]
                                            ${pd.status >= 2 ? 'green_text': 'red_text'}
                                            `}
                                            >
                                                <img src={pd.status >= 2 ? successIcon : pendingIcon } className='w-[8px] mt-[1px]' alt="success-icon" />
                                                <span className='pt-[1px] font-bold'>Decision</span>
                                            </div>

                                            <div className={`text-[10px] pt-[5px]
                                            ${pd.isDecisioningCompleted && pd.status === 2 ? 'green_text' : 'red_text' }
                                            `}> {'>'} </div>

                                            <div
                                            className={`flex gap-[3px]
                                            min-w-[30px] pt-[4px] rounded-[5px]
                                            ${'red_text'}
                                            `}
                                            >
                                                <img src={ pendingIcon } className='w-[8px] mt-[1px]' alt='pending-icon'/>
                                                <span className='pt-[1px] font-bold'>Accept</span>
                                            </div>
                                    </div>
                                </div>
                                {/* } */}
                                </div>
                            )
                        })}
                    </div>
                }
            </>
            }
            <div className='izi_list'>
                <div className='flex flex-row justify-between'>

                        <div 
                        className='text-[12px] font-bold'
                        style={{color:'var(--primaryColourHexCode)'}}
                        >
                        {sortedLoans.length > 0 && 'BNPL History' }</div>


                </div>


                {!loading &&    
                <>
                    {sortedLoans.map((this_loan, index)=>{
                        let repayment = this_loan.repayments
                        let remainderAmount = 0
                        let originalAmount = 0
                        repayment.forEach((to_repay, index) => {
                            remainderAmount += to_repay.remainderAmount
                            originalAmount += to_repay.originalAmount 
                        });
                        return(
                            <div 
                            key={index}
                            >
                                {(expandItem === -1 || expandItem === index) &&
                                    <div className='' 
                                        onClick={()=>{
                                            toggleExpand(index)

                                            // setCurrentRepayment([])
                                        }}
                                        >

                                        <div className={`list_item ${expandItem ===  index ? 'active': 'not-active'}`}>
                                            <div className='top'>
                                                <div className='text-[10px] font-bold text-gray-600 pt-[4px]'>{removeTime(this_loan.updatedAt)}</div>

                                            </div>
                                            <div className='inner'>
                                                <div>
                                                    <div className={`amount 
                                                    ${remainderAmount > 0 ? 'red_text' : 'green_text'}
                                                    `}>{convertToMoney(remainderAmount)}</div>
                                                    <div className='label'>{remainderAmount > 0 ? 'Outstanding amount' : 'Fully Paid'}</div>

                                                    <div className='borrowed'>Total amount borrowed: {convertToMoney(originalAmount)}</div>

                                                </div>

                                            </div>
                                        </div>
                                        {expandItem ===  index &&
                                        <div className='details'>
                                            <div className='title' >Repayment Schedule 

                                            </div>

                                            {currentRepayments.map((this_repayment, index_)=>{
                                                return(
                                                    <div className='details_item' key={index_}>
                                                        <div>

                                                        <div className='date_requested'>Due: {removeTime(this_repayment.dueDate)}</div>
                                                        <div className='amount'>{convertToMoney(this_repayment.remainderAmount)}</div>
                                                        <div className={`status ${this_repayment.paymentCompleted ? 'green_text': 'red_text'}`}>status: {`${this_repayment.paymentCompleted ? 'Paid': 'Not Paid'}`}</div>
                                                        </div>
                                                        {partialRepaymentId === this_repayment.id && 
                                                        Number(this_repayment.remainderAmount) > 0 &&
                                                            <div className='button h-[50px]'
                                                            onClick={()=>{
                                                                makePayment(this_loan.id, false)
                                                            }}
                                                            >Make Partial Payment</div>
                                                        }
                                                    </div>
                                                )
                                            })}
                                            {Number(remainderAmount) > 0 &&
                                            <div >
                                                <div 
                                                    className='btn'
                                                    onClick={()=>{
                                                        makePayment(this_loan.id, true)
                                                    }}
                                                >Make Full Repayment</div>
                                            </div>
                                            }
                                        </div>
                                        }
                                    </div>
                                }
                            </div>
                        )
                    })}
                </>
                }
                {loading &&
                    <>
                        <div className='loading'></div>
                        <div className='loading_text'>{loadingText}</div>
                    </>
                }
            </div>
            
            <Footnotes />
        </div >
    );
}